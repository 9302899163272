import { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { pickDefaultValues } from '../../../lib/form';
import { Formik, Form, Field } from 'formik';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TextField } from 'formik-mui';
import { litigatorsListingStore } from '../LitigatorsListingStore';
import { DropzoneArea } from 'material-ui-dropzone';
import './style.css';
import { Box, Grid } from '@material-ui/core';
import ServerSelect from 'components/ServerSelect';
import { LitigatorType } from '../../../data/types';
import LitigatorTypesRepo from '../../../data/repositories/LitigatorTypesRepo';
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import SelectField from 'components/SelectField';
import { observable, action } from 'mobx';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import Thumb from 'components/Thumb';
import { litigatorAvatarsStore } from '../../../data/stores/LitigatorAvatarsStore';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextFIELD from '@mui/material/TextField';
import moment from 'moment';
import CustomMaterialUiSelect from 'components/CustomMaterialUiSelect';

interface AddLitigatorModalProps {
  showModal: boolean;
  closeModalForm: () => void;
  openItem: any;
}

const DEFAULT_VALUES = {
  id: '',
  litigator_status: 'active',
  litigator_firstname: '',
  litigator_lastname: '',
  gender: '',
  phone: '',
  private_phone: '',
  email: '',
  private_email: '',
  professional_qualifications: '',
  directory_number: '',
  directory_input_date: new Date(),
  mandate_end_date: new Date(),
  identification_document_number: '',
  document_number: '',
  document_name: '',
  place: '',
  bank_account_number: '',
  bank_name: '',
  address: '',
  litigator_type: null,
  litigator_type_id: null,
  biography: EditorState.createEmpty()
};

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600]
  }
}));

const genderOptions = [
  { label: 'Мушки', value: 'Мушки' },
  { label: 'Женски', value: 'Женски' }
];

@observer
class AddLitigatorModal extends Component<AddLitigatorModalProps> {
  @observable gender: any = {};

  @observable switchValue: string = 'active';

  componentWillUnmount() {
    litigatorAvatarsStore.clearLitigatorAvatar();
  }

  @action
  setSwitchValue = (value: string) => {
    this.switchValue = value;
  };

  @action
  setGender = (gender: any) => {
    this.gender = gender;
  };

  onUpload = (data: any, openItem?: any) => {
    litigatorAvatarsStore.setFile(data);
    openItem
      ? litigatorAvatarsStore.updateLitigatorAvatar(data, openItem.id)
      : litigatorAvatarsStore.createLitigatorAvatar(data);
  };

  onSubmit = (data: any) => {
    const { openItem } = this.props;

    if (openItem && openItem.image_link === null) {
      data.image_link =
        process.env.REACT_APP_STORAGE_BASE_URL +
        '/' +
        litigatorAvatarsStore.imageLink;
    }

    if (openItem === undefined) {
      data.image_link =
        process.env.REACT_APP_STORAGE_BASE_URL +
        '/' +
        litigatorAvatarsStore.imageLink;
    }

    litigatorsListingStore.createLitigator(data);
  };

  getInitialValues = () => {
    const { openItem } = this.props;

    if (openItem && openItem.id) {
      if (openItem.litigator_status === 'active') {
        this.setSwitchValue('active');
      } else {
        this.setSwitchValue('not_active');
      }

      if (openItem.image_link && openItem.image_link !== '') {
        litigatorAvatarsStore.setImageLink(openItem.image_link);
      }

      const pickedValues = pickDefaultValues(DEFAULT_VALUES, openItem);

      const blocksFromHtml = htmlToDraft(openItem.biography);
      const { contentBlocks, entityMap } = blocksFromHtml;

      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);

      return {
        ...pickedValues,
        biography: editorState
      };
    }

    return DEFAULT_VALUES;
  };

  handleChangeSwitchLabel = () => {
    if (this.switchValue === 'active') {
      this.setSwitchValue('not_active');
    } else {
      this.setSwitchValue('active');
    }
  };

  render() {
    const { showModal, closeModalForm, openItem } = this.props;
    const { imageLink, file, setImageLink } = litigatorAvatarsStore;

    if (imageLink) {
      setImageLink(imageLink);
    }

    return (
      <div style={{ position: 'relative' }}>
        <Modal show={showModal} onHide={closeModalForm}>
          <Modal.Header
            style={{
              backgroundColor: '#EEEEEE',
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Додавање новог миритеља односно арбитра
            <Button
              onClick={() => {
                litigatorsListingStore.showAddButton();
                litigatorAvatarsStore.clearLitigatorAvatar();
                closeModalForm();
              }}
              variant="contained"
              style={{
                backgroundColor: '#fb8c00',
                fontSize: 14,
                fontWeight: 400,
                color: 'white',
                width: '205px',
                height: '36px',
                borderRadius: '4px'
              }}
            >
              <ArrowBackIosIcon style={{ fontSize: 12, color: 'white' }} />{' '}
              НАЗАД
            </Button>
          </Modal.Header>
          <Formik
            onSubmit={this.onSubmit}
            initialValues={this.getInitialValues()}
            render={({
              values,
              setFieldValue,
              errors,
              touched,
              setFieldTouched
            }) => (
              <Form noValidate className="form-horizontal">
                <Modal.Body>
                  <Col md={3}>
                    <GreenSwitch
                      onChange={() => {
                        setFieldValue(
                          'litigator_status',
                          this.switchValue === 'active'
                            ? 'not_active'
                            : 'active'
                        );
                        this.handleChangeSwitchLabel();
                      }}
                      defaultChecked={
                        this.switchValue === 'active' ? true : false
                      }
                    />

                    {this.switchValue === 'active' ? 'Актуелан/на' : 'Бивши/а'}

                    {/* <DropzoneArea
                      initialFiles={[this.imageLink]}
                      dropzoneText={''}
                      showPreviewsInDropzone={true}
                      filesLimit={1}
                      onChange={(file: any) => setFieldValue('file', file)}
                    /> */}
                    <div
                      style={{
                        border: '2px dashed #ccc',
                        height: '300px',
                        overflow: 'hidden',
                        maxWidth: '330px'
                      }}
                    >
                      <input
                        id="file"
                        name="file"
                        type="file"
                        style={{
                          position: 'absolute',
                          height: '100%',
                          opacity: 0
                        }}
                        onChange={(event: any) => {
                          openItem && openItem.id
                            ? this.onUpload(
                                event.currentTarget.files[0],
                                openItem
                              )
                            : this.onUpload(event.currentTarget.files[0]);
                          //setFieldValue('file', event.currentTarget.files[0]);
                        }}
                        className="form-control"
                      />
                      {imageLink && !file ? (
                        <img
                          style={{
                            width: '100%',
                            maxWidth: '330px',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                          src={imageLink}
                        />
                      ) : null}
                      <Thumb file={file} />
                    </div>
                  </Col>
                  <Col md={9}>
                    <Row>
                      <Col md={3}>
                        <Field
                          component={TextField}
                          name="litigator_firstname"
                          type="text"
                          label="Име"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <Field
                          component={TextField}
                          name="litigator_lastname"
                          type="text"
                          label="Презиме"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <CustomMaterialUiSelect
                          setFieldValue={setFieldValue}
                          value={values.gender}
                          fullValue={values.gender}
                          data={genderOptions}
                          display="label"
                          name="gender"
                          fullValueName="label"
                          valueAttr="value"
                          label="Пол*"
                        />
                      </Col>
                      <Col md={3}>
                        <Field
                          component={TextField}
                          name="document_name"
                          type="text"
                          label="Име за документа*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <Field
                          component={TextField}
                          name="phone"
                          type="text"
                          label="Телефон*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <Field
                          component={TextField}
                          name="private_phone"
                          type="text"
                          label="Приватни телефон"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <Field
                          component={TextField}
                          name="email"
                          type="email"
                          label="Електронска пошта*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <Field
                          component={TextField}
                          name="private_email"
                          type="email"
                          label="Приватна електронска пошта"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <CustomMaterialUiSelect
                          setFieldValue={setFieldValue}
                          value={values.litigator_type_id}
                          fullValue={values.litigator_type}
                          repo={LitigatorTypesRepo}
                          display="name"
                          name="litigator_type_id"
                          fullValueName="litigator_type"
                          valueAttr="id"
                          label="Миритељ/арбитар*"
                        />
                      </Col>
                      <Col md={6}>
                        <Field
                          component={TextField}
                          name="professional_qualifications"
                          type="text"
                          label="Стручна спрема"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <Field
                          component={TextField}
                          name="identification_document_number"
                          type="text"
                          label="Број идентификационог документа*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <Field
                          component={TextField}
                          name="directory_number"
                          type="text"
                          label="Број решења уписа у именик*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            InputProps={{ style: { fontSize: 16 } }}
                            label="Датум решења уписа у именик*"
                            value={values.directory_input_date}
                            inputFormat="dd.MM.yyyy"
                            onChange={newValue => {
                              setFieldValue(
                                'directory_input_date',
                                moment(newValue).format('YYYY-MM-DD')
                              );
                            }}
                            renderInput={params => (
                              <TextFIELD
                                variant="standard"
                                name="directory_input_date"
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {/* <Field
                          component={TextField}
                          name="directory_input_date"
                          type="date"
                          label="Датум решења уписа у именик*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        /> */}
                      </Col>
                      <Col md={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            InputProps={{ style: { fontSize: 16 } }}
                            label="Датум престанка важења мандата*"
                            value={values.mandate_end_date}
                            inputFormat="dd.MM.yyyy"
                            onChange={newValue => {
                              setFieldValue(
                                'mandate_end_date',
                                moment(newValue).format('YYYY-MM-DD')
                              );
                            }}
                            renderInput={params => (
                              <TextFIELD
                                variant="standard"
                                name="mandate_end_date"
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {/* <Field
                          component={TextField}
                          name="mandate_end_date"
                          type="date"
                          label="Датум престанка важења мандата*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        /> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Field
                          component={TextField}
                          name="place"
                          type="text"
                          label="Место"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <Field
                          component={TextField}
                          name="address"
                          type="text"
                          label="Адреса"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Field
                          component={TextField}
                          name="bank_account_number"
                          type="text"
                          label="Број рачуна"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <Field
                          component={TextField}
                          name="bank_name"
                          type="text"
                          label="Назив банке"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="form-group">
                          <label
                            style={{
                              fontSize: 16,
                              fontWeight: 500,
                              marginTop: 20
                            }}
                          >
                            Биографија
                          </label>
                          <Editor
                            editorState={values.biography}
                            wrapperClassName="textEditorWrap"
                            toolbarClassName="textEditorToolbar"
                            editorClassName="textEditorInput"
                            onEditorStateChange={(option: any) => {
                              return setFieldValue('biography', option);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* Add more fields */}
                  </Col>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="text"
                    style={{ fontSize: 14, color: 'black' }}
                  >
                    <CloseIcon style={{ fontSize: 20, color: 'black' }} />
                    ОБРИШИ
                  </Button>
                  <Button
                    onClick={() => {
                      litigatorsListingStore.showAddButton();
                      litigatorAvatarsStore.clearLitigatorAvatar();
                    }}
                    type="submit"
                    variant="text"
                    style={{ fontSize: 14, color: 'black' }}
                  >
                    <CheckIcon style={{ fontSize: 20, color: 'black' }} />
                    САЧУВАЈ
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          />
        </Modal>
      </div>
    );
  }
}

export default AddLitigatorModal;
